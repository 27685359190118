<template>

  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="电梯信息" name="first">
      <cq-elevator></cq-elevator>
    </el-tab-pane>
    <el-tab-pane label="状态信息" name="second">
      <cq-fault></cq-fault>
    </el-tab-pane>
    <el-tab-pane label="维保信息" name="third">
      <cq-wb></cq-wb>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
  import CqElevator from "@/views/passport/chongqing/CqElevator";
  import CqFault from "@/views/passport/chongqing/CqFault";
  import CqWb from "@/views/passport/chongqing/CqWb";
  export default {
    components: {CqWb, CqFault, CqElevator},
    data() {
      return{
        activeName: "first",
      };
    },
    mounted() {

    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
