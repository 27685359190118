<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="elevators/passport">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('elevator.elevatorType', '梯种')">
          <el-select v-model="search.productTypeId" :style="{ width: $l('250px', '150px') }"
                     :placeholder="$t('elevator.elevatorType')" clearable>
            <el-option v-for="item in productTypeOptions" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('elevator.onlineStatus', '在线状态')">
          <el-select v-model="search.onlineStatus" :placeholder="$t('elevator.onlineStatus')"
                     :style="{ width: $l('150px', '100px') }" clearable>
            <el-option v-for="item in onlineStatusOptions" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('elevator.accessStatus')">
          <el-select v-model="search.accessStatus" :placeholder="$t('elevator.accessStatus')"
                     :style="{ width: $l('200px', '100px') }" clearable>
            <el-option v-for="item in accessStatus" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('elevator.joinStatus', '入网状态')">
          <el-select v-model="search.inNetState" :placeholder="$t('elevator.joinStatus')"
                     :style="{ width: $l('150px', '100px') }" clearable>
            <el-option v-for="item in inNetStateOptions" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="greenBtn" @click="exportData">导出</el-button>
      </template>
      <el-table-column prop="no" :label="$t('elevator.no')" width="120" align="center">
        <template v-slot="scope">
          <router-link :to="'/elevator/' + scope.row.id" target="_blank">{{scope.row.no}}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="regNo" :label="$t('elevator.registerCode')" width="200"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="dtuCode" :label="$t('elevator.dtuCode')" width="150" align="center"></el-table-column>
      <el-table-column prop="nodeCode" :label="$t('elevator.nodeCode')" width="100" align="center"></el-table-column>
      <el-table-column prop="name" :label="$t('elevator.name')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="useUnitName" :label="$t('elevator.useUnit')" show-overflow-tooltip></el-table-column>
      <el-table-column :label="$t('elevator.accessStatus')" align="center" width="80">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.accessStatus === 1">{{$t("elevator.accessed")}}</el-tag>
          <el-tag v-else type="info">{{$t("elevator.notAccessed")}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$t('device.status')" align="center" width="80">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.onlineStatus">{{$t("device.online")}}</el-tag>
          <el-tag v-else type="info">{{$t("device.offline")}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="inNetState"
        :label="$t('elevator.joinStatus')"
        width="80"
        align="center">
        <template v-slot="scope">
          <el-tag v-if="scope.row.inNetState" type="success">{{$t("elevator.joined")}}</el-tag>
          <el-tag v-else type="info">{{$t("elevator.notJoined")}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.operation')" width="150" align="center" fixed="right">
        <template v-slot="scope">
          <el-button type="text" @click="register(scope.row)">注册</el-button>
          <el-button type="text" @click="unbind(scope.row)">取消注册</el-button>
          <el-button type="text" v-if="false" @click="statusC1(scope.row)">C1</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </div>
</template>
<script>

  import auth from "@/util/auth";

  const moduleName_productType = "elevatorProductTypes";

  export default {
    data() {
      return {
        loading: false,
        showAside: false,
        superAdmin: auth.getUsername() === "superAdmin",
        search: {
          filter: "",
          productTypeId: "",
          integrationKey: "chongqing",
          maintComId: "",
          quantityOrganId: "",
          createComId: "",
          installComId: "",
          remakeComId: "",
          useUnitId: "",
          onlineStatus: "",
          inNetState: "",
          accessStatus: "",
          leaveStartTime: "",
          leaveEndTime: "",
          installStartTime: "",
          installEndTime: "",
          inNetStartTime: "",
          inNetEndTime: "",
          useStartTime: "",
          useEndTime: "",
          districtId: "",
        },
        productTypeOptions: [],
        accessStatus: [
          {value: 0, label: this.$t("elevator.notAccessed")},
          {value: 1, label: this.$t("elevator.accessed")},
        ],
        onlineStatusOptions: [
          {value: 0, label: this.$t("device.offline")},
          {value: 1, label: this.$t("device.online")},
        ],
        inNetStateOptions: [
          {value: "false", label: this.$t("elevator.notJoined")},
          {value: "true", label: this.$t("elevator.joined")},
        ],
      };
    },
    mounted() {
      this.getList(1);
      this.getProductTypeOptions();
    },
    methods: {
      onNodeClick(data) {
        if (data.type === "useUnit") {
          this.search.useUnitId = data.id;
          this.search.districtId = "";
          this.getList(1);
        }
      },
      register(row) {
        this.$http.post(window.config.integrationUrl + "cq/register/" + row.regNo).then(res => {
          if (res) {
            this.$message.success(this.$t("elevator.tip.operateSuccess"));
          } else {
            this.$message.error(this.$t("elevator.tip.operateError"));
          }
        }).catch((error) => {
          this.$message.error(this.$t("elevator.tip.operateError") + ", " + error.response.data.message);
        });
      },
      statusC1(row) {
        this.$http.post(window.config.integrationUrl + "cq/addTestC1/" + row.regNo).then(res => {
          if (res) {
            this.$message.success(this.$t("elevator.tip.operateSuccess"));
          } else {
            this.$message.error(this.$t("elevator.tip.operateError"));
          }
        }).catch((error) => {
          this.$message.error(this.$t("elevator.tip.operateError") + ", " + error.response.data.message);
        });
      },
      unbind(row) {
        this.$http.post(window.config.integrationUrl + "cq/unbind/" + row.regNo).then(res => {
          if (res) {
            this.$message.success(this.$t("elevator.tip.operateSuccess"));
          } else {
            this.$message.error(this.$t("elevator.tip.operateError"));
          }
        }).catch((error) => {
          this.$message.error(this.$t("elevator.tip.operateError") + ", " + error.response.data.message);
        });
      },
      onAreaClick(data) {
        this.search.districtId = data.id;
        this.search.useUnitId = "";
        this.getList(1);
      },
      onNodeClear() {
        this.search.districtId = "";
        this.search.useUnitId = "";
        this.getList(1);
      },
      getList(pageIndex) {
        this.loading = true;
        this.$refs.vmTable.getList(pageIndex);
        this.loading = false;
      },
      exportData() {
        window.open(window.config.integrationUrl + "cq/device/export");
      },
      getProductTypeOptions() {
        this.$api.getList(moduleName_productType).then(response => {
          for (let productType of response.data) {
            let item = "";
            if (this.$i18n.isCn) {
              item = {value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeName};
            } else {
              item = {value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeNameEn};
            }
            this.productTypeOptions.push(item);
          }
        }).catch(() => {
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      handleCollapse() {
        this.showAside = !this.showAside;
        if (this.showAside) {
          this.$refs.aside.$el.setAttribute("style", "margin-right: 20px");
        } else {
          this.$refs.aside.$el.setAttribute("style", "margin-right: 0px");
        }
      },
    },
  };
</script>
<style lang="scss" scoped></style>
