<template>
  <div class="vm-table-new">
    <div v-if="this.$slots.otherButton||showAdd" class="tools-bar">
      <el-button
        v-if="showAdd"
        class="add"
        type="primary"
        icon="el-icon-plus"
        @click="handleAddClick">
        {{$t('common.add')}}
      </el-button>
      <slot name="otherButton"></slot>
    </div>
    <div
      ref="adSearch"
      class="ad-search"
      :style="{height:collapseSearch? '110px':searchBarHeight}"
    >
      <el-button class="ad-search-btn" type="primary" icon="el-icon-search" @click="handleSearchClick">
        {{$t('common.search')}}
      </el-button>
      <div class="ad-search-content">
        <slot name="adSearch"></slot>
      </div>
      <div
        v-if="showCollapse"
        class="more-search"
        @click="collapseSearch=!collapseSearch"
      >
        <i class="el-icon-arrow-down" :style="{transform:collapseSearch? 'rotate(0deg)':'rotate(180deg)'}"></i>{{collapseSearch?$t('common.more'):$t('common.less')}}
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      stripe
      :data="tableData"
      :height="height"
      @selection-change="handleSelectionChange">
      <el-table-column
        v-if="multiSelection"
        type="selection"
        width="55">
      </el-table-column>
      <slot></slot>
    </el-table>
    <div v-if="showPage" style="margin-top: 16px;overflow: hidden">
      <el-pagination
        class="page"
        :page-size="pageSize"
        :current-page="currentPage"
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentChange"></el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      tableData: {
        type: Array,
        default() {
          return [];
        },
      },
      total: {
        type: Number,
        default: 0,
      },
      pageSize: {
        type: Number,
        default: 10,
      },
      currentPage: {
        type: Number,
        default: 1,
      },
      searchPlaceholder: {
        type: String,
        default: "search",
      },
      showAdd: {
        type: Boolean,
        default: true,
      },
      showPage: {
        type: Boolean,
        default: true,
      },
      multiSelection: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      height: [String],
    },
    data() {
      return {
        collapseSearch: false,
        showCollapse: false,
        searchBarHeight: "auto",
      };
    },
    mounted() {
      let searchBarHeightInt = this.$refs.adSearch.offsetHeight;
      console.log(searchBarHeightInt);
      if (searchBarHeightInt > 120) {
        this.collapseSearch = true;
        this.searchBarHeight = searchBarHeightInt + "px";
        this.showCollapse = true;
      }
    },
    methods: {
      handleCurrentChange(page) {
        this.$emit("page-change", page);
      },
      handleSearchClick() {
        this.$emit("search-click");
      },
      handleAddClick(event) {
        this.$emit("add-click", event);
      },
      handleSelectionChange(val) {
        this.$emit("selection-change", val);
      },
    },
  };
</script>

<style lang="scss">
  @import "../style/variables";

  .vm-table-new {
    .tools-bar {
      padding-bottom: 10px;
      overflow: hidden;
      .search-bar {
        float: right;
        right: 0;
        width: 30%;
        min-width: 290px;
        i {
          cursor: pointer;
        }
      }
      .add {
        i {
          padding-right: 4px;
        }
      }
    }
    .ad-search {
      position: relative;
      overflow: hidden;
      background-color: #F4F7FA;
      border-radius: 4px;
      border: 1px solid #ECEEF3;
      text-align: center;
      transition: height 0.2s ease-in-out;
      margin-bottom: 8px;
      .ad-search-content {
        padding: 10px 115px 0 15px;
      }
      .ad-search-btn {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
      .el-form-item {
        margin-bottom: 10px;
      }
      .more-search {
        position: absolute;
        font-size: 14px;
        padding: 5px 10px 5px 10px;
        color: #555;
        background-color: white;
        cursor: pointer;
        border-radius: 3px 0 0 0;
        border: 1px solid #ECEEF3;
        bottom: -1px;
        right: -1px;
        i {
          transition: transform 0.2s linear;
          margin-right: 5px;
        }
      }
    }
    .page {
      float: right;
    }
  }

  .spin-container {
    position: relative;
    .loading {
      @include gravityCenter;
      background: rgba(255, 255, 255, 0.7);;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
</style>
