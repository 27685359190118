<template>
  <div style="padding-bottom: 32px;">
    <el-tabs v-model="activeName">
      <el-tab-pane label="重庆市" name="first">
        <CqList></CqList>
      </el-tab-pane>
      <el-tab-pane label="重庆江北区" name="second">
        <jiang-bei-list></jiang-bei-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import CqList from "@/views/passport/chongqing/CqList";
  import JiangBeiList from "@/views/passport/chongqing/JiangBeiList";

  export default {
    components: { CqList, JiangBeiList},
    data() {
      return {
        activeName: "first",
      };
    },
    methods: {},
  };
</script>

<style scoped></style>
