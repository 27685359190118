<template>
  <div>
    <vm-table ref="vmTable" :table-data="tableData" :total="total" :loading="loading" :page-size="15" :current-page="currentPage"
      show-ad-search :show-add="false" @page-change="changePage" @search-click="handleSearch">
      <template slot="toolbar">
        <el-button class="greenBtn" @click="exportData">导出</el-button>
      </template>
      <el-form slot="adSearch" class="form" label-width="120px">
        <div class="vm-separate">
          <el-form-item label="电梯设备代码">
            <el-input v-model="adSearch.regNo" clearable></el-input>
          </el-form-item>
          <el-form-item label="状态代码">
            <el-input v-model="adSearch.faultCode" clearable></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item label="开始时间">
            <el-date-picker v-model="adSearch.startTime" value-format="yyyy-MM-dd 00:00:00" type="date"
              style="width: 100%">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间">
            <el-date-picker v-model="adSearch.endTime" value-format="yyyy-MM-dd 23:59:59" type="date" style="width: 100%">
            </el-date-picker>
          </el-form-item>
        </div>
      </el-form>
      <el-table-column prop="city" label="城市" width="90" align="center"></el-table-column>
      <el-table-column prop="area" label="地区" align="center"></el-table-column>
      <el-table-column prop="community" label="小区" align="center"></el-table-column>
      <el-table-column prop="regNo" label="电梯设备代码" width="180" align="center"></el-table-column>
      <el-table-column prop="faultCode" label="状态代码" width="80" align="center"></el-table-column>
      <el-table-column prop="faultDesc" label="状态说明" align="center"></el-table-column>
      <el-table-column prop="faultTime" label="时间" align="center"></el-table-column>
    </vm-table>
  </div>
</template>

<script>
import VmTable from "../../../components/VmTableNew.vue";

export default {
  components: { VmTable },
  data() {
    return {
      loading: false,
      currentPage: 1,
      filter: "",
      total: 0,
      tableData: [],
      adSearch: {
        regNo: "",
        faultCode: "",
        startTime: "",
        endTime: "",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.currentPage = pageNum;
      this.loading = true;
      let params = {
        pageNo:pageNum,
        ...this.adSearch,
      };
      this.$api.getList(window.config.integrationUrl + "cq/device/faultList", params).then(res => {
        this.loading = false;
        console.log(res);
        let data = res.data.data;
        this.tableData = data.records;
        this.total = data.total;
      }).catch(() => {
        this.loading = false;
        this.$message.error(this.$t("common.tip.getDataError"));
      });
    },
    handleSearch() {
      this.getList(1);
    },
    changePage(pageIndex) {
      this.getList(pageIndex);
    },
    exportData() {
      let params = Object.keys(this.adSearch).map(key => {
        let value = this.adSearch[key];
        if (value) {
          return encodeURIComponent(key) + "=" + encodeURIComponent(value);
        }
      }).join("&");
      window.open(window.config.integrationUrl + "cq/device/exportFault?" + params);
    },
  },
};
</script>

<style lang="scss" scoped></style>
